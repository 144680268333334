 

.social-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    max-width: 200px;
    margin-bottom: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .social-icon {
    margin-bottom: 8px;
  }

  .email-link {
    color: #87ceeb;
  }  
  
  .facebook {
    background-color: #4267B2;
  }
  
  .linkedin {
    background-color: #0A66C2;
  }
  
  .instagram {
    background-color: #C13584;
  }
  
  .social-icon {
    margin-right: 8px;
  }
  