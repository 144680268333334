.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden; /* Add this line */
}

header {
  background-color: #333;
  padding: 0px;
  position: sticky;
  top: 0;
  z-index: 1;
}

.iframe-large-container {
  border: 0px;
  width: 1000px;
  max-width: 100%;
  height: 90vh;
  overflow: hidden;
}

.page-content {
  height: 100vh; /* Set the height to 100% of the viewport height */
  padding-top: 70px; /* Add padding to the content area */
  padding-bottom: 50px;
  padding-left: 50px;
  padding-right: 50px;
  box-sizing: border-box; /* Include padding in the total height */
  overflow: auto; /* Enable scrolling if content overflows */
  text-align: center;
  background-color: #120909; /* Set the background color to dark */
  color: #fff; /* Set the text color to white */
}

.terms-content {
  height: 100%; /* Set the height to 100% of the viewport height */
  padding-top: 40px; /* Add padding to the content area */
  box-sizing: border-box; /* Include padding in the total height */
  overflow: hidden; /* Enable scrolling if content overflows */
  background-color: #120909; /* Set the background color to dark */
  text-align: left;
  overflow: hidden;
}

nav {
  display: flex;
  justify-content: center;
  z-index: 10;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  padding: 5px;
}


.nav-links li {
  margin-right: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
}

.nav-icon {
  margin-right: 5px;
}

.contact-us-icon {
  margin-right: 5px;
  color: #fff;
  background-color: #333;
  border-radius: 50%;
  padding: 5px;
}

main {
  flex: 1;
  background-color: #f8f8f8;
}

footer {
  background-color: #333;
  padding: 0px;
  text-align: center;
  margin-top: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2; /* Increase the z-index value */
}

footer p {
  color: #fff;
  font-size: 15px;
}



@media (max-width: 768px) {

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .nav-links.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%; /* Position the menu below the hamburger menu */
    left: 0;
    background-color: #333;
    padding: 20px;
    width: 100%;
    flex-direction: column;
  }

  .nav-links li {
    text-align: center;
    margin-bottom: 22px;
  }

  .hamburger-menu {
    display: flex;
    flex-direction: column;
    background-color: #333; /* Set the background color to dark */
    position: absolute;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    left: 0;
    height: 30px;
    padding: 15px;
    cursor: pointer;
  }
  
  .hamburger-menu span {
    width: 30px;
    height: 3px;
    margin-right: 0px;
    left: 0;
    background-color: #fff;
    margin-bottom: 4px;
  }
}

