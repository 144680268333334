.section-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  margin-bottom: 10px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.description:last-child {
  margin-bottom: 0;
}

.description strong {
  color: #007bff;
}

.subtitle {
  font-size: 15px;
  margin-bottom: 10px;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.description em {
  font-style: italic;
  color: #28a745;
}

.clients-section {
  margin-top: 40px;
}

.clients-list {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  /* Add this line */
}

.clients-list li {
  margin: 10px 0;
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: row;
  /* Add this line */
  justify-content: center;
  /* Add this line */
}

.client-image {
  scale: 0.5;
  max-width: 600px;
  margin-bottom: -40px;
  height: 150px;
  margin-right: 35px;
}