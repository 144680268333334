
  .goal-grid {
    display: flex;
    justify-content: center;
  }
  
  .goal-grid-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  

  .goal-card {
    position: relative;
    flex: 0 0 300px;
    width: 300px;
    height: 300px;
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .goal-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  
  .goal-card h3 {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    margin-left: 5px;
    margin-right: 5px;
    margin-top: 0;
    padding: 10px;
    font-size: 16px;
    text-align: center;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.7);
  }
  
  .back-button {
    margin-bottom: 10px;
    position: relative; /* Add relative positioning */
    z-index: 2; /* Increase the z-index to place the button above the iframe */
  }
  
  /* Mobile responsive styles */
  @media (max-width: 768px) {
    .goal-grid {
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    }
  }
  
  @media (max-width: 480px) {
    .goal-grid {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }
  }
 