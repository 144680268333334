  
  .product-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .product-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .product-item {
    margin-bottom: 15px;
    margin-top: 10px;
  }
  
  .product-list a {
    color: #87ceeb; /* Set the color to light blue */
  }
  
  .product-list a:hover {
    text-decoration: underline; /* Add underline on hover if desired */
  }

  .app-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .app-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: none;
  }
  
  .app-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .download-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    color: #fff;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .app-store {
    background-color: #007bff;
  }
  
  .google-play {
    background-color: #28a745;
  }
  
  .store-icon {
    margin-right: 8px;
  }
  