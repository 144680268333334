.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

p {
  font-size: 20px;
}

.background-video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  filter: blur(5px); /* Adjust the blur strength as desired */
}

.content-overlay {
  position: relative;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5); /* Add a semi-transparent background color */
}

/* Add any additional styling as needed */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  width: 150px;
  margin-bottom: 20px;
  height: 150px;
}
